.hr-submission {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  .wrapper {
    display: flex;
    width: 100%;
    max-width: 100vw !important;
    height: 100%;
    & > div {
      height: 99%;
      padding-bottom: 1%;
    }
    .left {
      width: 60%;
      background: rgba(0, 0, 0, 0.2);
    }
    .right {
      width: 40%;
      // border: 1px solid;
      background: #fff;
      padding: 50px 40px;
      padding-top: 36px;
      position: relative;
      overflow-y: scroll;
      height: 90%;
      overflow-x: hidden;
      .cross {
        position: absolute;
        top: 0px;
        right: 0;
        cursor: pointer;
        width: fit-content;
      }
      .header {
        color: #000;
        font-size: 24px;
        font-family: "Inter";
        font-weight: 600;
        line-height: 140%;
      }
      .title {
        & > div:nth-child(1) {
          color: #8c8c8c;
          font-size: 20px;
          font-family: "Inter";
          font-weight: 600;
          line-height: 20px;
          text-transform: uppercase;
          margin-top: 46px;
        }
        & > div:nth-child(2) {
          color: #474747;
          font-size: 16px;
          font-family: "Inter";
          line-height: 140%;
          margin-top: 10px;
        }
      }
      .options {
        display: flex;
        gap: 20px;
        margin-top: 24px;
        .dropDown {
          width: 50%;
          height: 50px;
        }
        .button-wrapper > button {
          border-radius: 4px;
          background: var(--black-high, #212121);
          padding: 7px 16px;
          width: 128px;
          cursor: pointer;
          color: (#fff);
          /* font/button/large/sentence */
          font-size: 14px;
          font-family: "Inter";
          font-weight: 600;
          line-height: 24px;
        }
      }
      .divider {
        border: 0.5px solid #c4c4c4;
        width: 100%;
        margin: 40px 0;
        margin-bottom: 28px;
      }
      .prev {
        color: #474747;
        font-size: 18px;
        font-family: "Inter";
        font-weight: 500;
        line-height: 140%;
      }
      .second {
        width: 100%;
        background: #f7f7f7;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        margin-top: 15px;
        & > div {
          border-bottom: 1px solid #d5d5d5;
          padding: 21px 22px;
        }
        & > div:nth-last-child(1) {
          border-bottom: none;
        }
        .p-rounds {
          background: inherit;
          display: flex;
          & > div {
            background: inherit;
            width: 50%;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #969696;
            & > span {
              font-weight: 600;
              font-size: 24px;
              line-height: 140%;
              color: #212121;
            }
          }
        }
        .round-3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;

          overflow-y: auto;
          color: #969696;
          & > span {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            color: #212121;
          }
        }
        .r3-each {
          display: flex;
          justify-content: space-between;
          color: #000;
          font-size: 16px;
          font-family: "Inter";
          font-weight: 500;
          line-height: 26px;
        }
      }
      .comments {
        margin-top: 17px;
        border-radius: 8px;
        border: 1px solid var(--light-neutral-light-gray-300, #d4d5d9);
        color: #212121;

        /* font/component specific/input value */
        font-size: 14px;
        font-family: "Inter";
        line-height: 20px;
        padding: 12px 16px;
      }
    }
  }
}

.dropDown > div > div > div > div > input:focus {
  /* border: 1px solid red !important; */
  outline: none !important;
}
