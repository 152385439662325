.forgotPassword {
  margin: 50px 0;
  // height: 100vh;
  overflow-y: auto;
  .forgot-wrapper {
    max-width: 505px;
    // width: 100%;
    width: fit-content;
    border-radius: 10px;
    border: 0.5px solid #878787;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    padding: 30px 40px;
    .mail {
      display: block;
      height: 5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      width: 5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .check {
      text-align: center;
      height: 2rem;
      width: 100%;

      svg {
        height: 100%;
        width: 100%;
        margin: 0 auto;
      }
    }
    hr {
      margin: 1rem 0;
      border: 1px solid #c1c1c1;
      margin-top: 3rem;
    }
    .verified {
      color: #2f2f2f;
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: 1rem;
      text-align: center;
    }
    .title {
      color: #000;
      font-family: "Poppins";
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    .desc {
      color: #000;
      font-size: 1rem;
      font-style: "Inter";
      font-weight: 400;
      margin-top: 2rem;
      text-align: center;
      width: 328px;
      span {
        color: blue;
      }
    }
    .items {
      label {
        font-weight: 600;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
      }
      input {
        border: 1px solid rgb(135, 135, 135);
        background-color: rgb(255, 255, 255);
        border-radius: 6px;
        height: 3rem !important;
        overflow: hidden;
        width: calc(100% - 20px);
        margin-top: 8px;
        outline: none;
        padding-left: 16px;
      }
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .resend {
        text-align: right;
        margin-top: 8px;
        span {
          cursor: pointer;
        }
        color: blue;
      }
    }
    .login-button {
      button {
        width: 100%;
        padding: 20px 0;
        border-radius: 6px;
        background: #000;
        margin-top: 32px;
        color: #fff;
        font-family: "Inter";
        font-size: 1rem;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .input-wrappers {
      //   margin: 0 40px;
      label {
        color: #000;
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-top: 16px;
      }
      .eye-input {
        width: calc(100% - 32px);
        border: 1px solid;
        display: flex;
        flex: 1 0;
        justify-content: space-between;
        margin-top: 8px;
        padding: 13px 16px;
        border-radius: 4px;
        border: 1px solid var(--black-20, rgba(17, 17, 19, 0.2));
        input {
          flex: 1;
          min-width: 328px;

          /* Body */
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          border: 1px solid #fff;
          outline: 1px solid #fff !important;
        }
        svg {
          width: 20px;
          margin-top: 5px;
        }
      }
    }
    .contains {
      width: 100%;
      max-width: 350px;
      color: #434343;
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      & > span:nth-child(1) {
        font-weight: 600;
      }
      margin-bottom: 20px;
    }
  }
}
