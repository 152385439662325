.login-page {
  margin: 100px 0;
  // height: 100vh;
  overflow-y: scroll;
  .login-wrapper {
    width: 100%;
    max-width: 504px;
    border-radius: 10px;
    border: 0.5px solid #878787;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
    padding: 30px 40px;
    margin: 0 auto;
    .login {
      color: #000;
      font-family: "Poppins";
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    .item1 {
      margin-top: 14px;
      input {
        width: calc(100% - 32px);
        padding: 13px 16px;
        margin-top: 8px;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        outline: none;
        border-radius: 4px;
        border: 1px solid rgba(17, 17, 19, 0.2);
      }
    }
    .input-wrapper {
      display: flex;
      flex-direction: column;
      label {
        margin-top: 20px;
      }
      .input-item2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid rgba(17, 17, 19, 0.2);
        margin-top: 8px;
        input {
          width: 90%;
          outline: none !important;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          padding: 13px 16px;
          border: 1px solid #fff;
        }
        span {
          padding-top: 13px;
          margin-right: 13px;
        }
      }
    }
    .remember {
      display: flex;
      justify-content: space-between;
      margin-left: -5px;
      margin-top: 20px;
      .remember-text {
        display: flex;
        align-items: center;
        label {
          color: #8e8e8e;
          font-family: "Nunito Sans";
          font-size: 1rem !important;
          font-style: normal;
          font-weight: 400;
          line-height: 156.5%; /* 21.91px */
          margin-left: 8px;
          cursor: default;
        }
        input[type="checkbox"] {
          /* ... */
          width: 20px; /* Increase the width as needed */
          height: 20px; /* Increase the height as needed */
          //   border: 1px solid;
          /* ... */
          cursor: pointer;
        }
      }
      .forgot {
        color: #4285f4;
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 156.5%; /* 25.04px */
        cursor: pointer;
      }
    }
    .login-button {
      button {
        width: 100%;
        padding: 20px 0;
        border-radius: 6px;
        background: #000;
        margin-top: 32px;
        color: #fff;
        font-family: "Inter";
        font-size: 1rem;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
