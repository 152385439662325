.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.wrapper {
  width: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}
.circle {
  width: 154px;
  height: 154px;
  border-radius: 50%;
  background: #d9d9d9;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: #000000;
  margin-top: 32px;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #a6a6a6;
  margin-top: 18px;
  width: 380px;
}
.buttonWrapper {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.buttonWrapper > button {
  width: 179px;
  height: 48px;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.buttonWrapper > button:nth-child(1) {
  border: 1.5px solid #212121;
}
.buttonWrapper > button:nth-child(2) {
  background: #212121;
  color: #fff;
}
