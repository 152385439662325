.buttonWrapper {
  display: flex;
  gap: 24px;
  margin: 0 auto;
  margin-top: 50px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 100px;
}
.buttonWrapper > button {
  border: 1px solid;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
}
