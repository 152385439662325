.reset-page {
  width: 100%;
  display: flex;
  gap: 20%;
  margin-top: 80px;
  height: 100vh;
  overflow-y: scroll;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
  }
  .back-button {
    color: #000;
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    width: 170px;
    margin-left: 72px;
    cursor: pointer;
    height: fit-content;
    margin-top: 32px;
    @media (max-width: 800px) {
      margin: 0 auto;
      margin-top: 32px;
    }
  }
  .main-content {
    max-width: 505px;
    width: 100%;
    border-radius: 10px;
    border: 0.5px solid #878787;
    background: #fff;
    height: fit-content;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
    @media (max-width: 800px) {
      margin: 0 auto;
      margin-top: 32px;
      margin-bottom: 200px;
    }
    .title {
      color: #222;
      text-align: center;
      font-family: "Inter";
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 44px;
      margin-bottom: 48px;
    }
    .input-wrappers {
      margin: 0 40px;
      label {
        color: #000;
        font-family: "Inter";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
      .eye-input {
        width: calc(100% - 32px);
        border: 1px solid;
        display: flex;
        flex: 1 0;
        justify-content: space-between;
        margin-top: 8px;
        padding: 13px 16px;
        border-radius: 4px;
        border: 1px solid var(--black-20, rgba(17, 17, 19, 0.2));
        input {
          flex: 1;

          /* Body */
          font-family: "Inter";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          border: 1px solid #fff;
          outline: 1px solid #fff;
        }
        svg {
          width: 20px;
          margin-top: 5px;
        }
      }
    }
    .line {
      border-bottom: 1px solid #c1c1c1;
      margin: 32px 0;
    }
    .contains {
      color: #434343;
      font-family: "Inter";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      margin: 10px 0 20px 0;
      padding: 0 129px 0 40px;
      & > span:nth-child(1) {
        font-weight: 600;
      }
    }
    .login-button {
      padding: 0 40px;
      margin-bottom: 100px;
      button {
        width: 100%;
        padding: 20px 0;
        border-radius: 6px;
        background: #000;
        margin-top: 32px;
        color: #fff;
        font-family: "Inter";
        font-size: 1rem;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .main-content-2 {
    height: 300px;
    padding: 30px;
    border-radius: 10px;
    border: 0.5px solid #878787;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
    .check {
      width: 100%;
      text-align: center;
      svg {
        width: 2rem;
        height: 2rem;
        margin-bottom: 20px;
      }
    }
    .login-button {
      padding: 0 40px;
      margin-top: 100px;
      button {
        width: 100%;
        padding: 20px 0;
        border-radius: 6px;
        background: #000;
        margin-top: 32px;
        color: #fff;
        font-family: "Inter";
        font-size: 1rem;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
