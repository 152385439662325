.container {
  width: calc(100% - 100px);
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.container > div {
  /* border: 1px solid red; */
  width: 48%;
}

.zoomScreen {
  min-width: 531px;
  width: 100%;
  min-height: 350px;
  aspect-ratio: 16/9;
  border: 1px solid;
}

.details {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 24px;
}

.meetDetails > div:nth-child(1) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  color: #000000;
}

.meetDetails > div:nth-child(2) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */

  text-transform: uppercase;

  color: #919191;
}

.resume {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  background: none;
  margin: 0;
  padding: 0;
  margin-top: 18px;
  cursor: pointer;
  color: #4285f4;
}

.comment > textarea {
  width: 100%;
  max-width: 527px;
  height: 56px;
  resize: none;
  outline: none;
  /* Primary/White_0

Default Page Background
*/
  background: #ffffff;
  /* Light Neutral/Light Gray_300

Line Separator, Disabled Border, Disabled Icons
*/
  border: 1px solid #d4d5d9;
  border-radius: 8px;
  padding: 16px;
  margin-top: 40px;
}

.knowMore {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
  color: #000000;
  padding: 15px;
  border: 1px solid #d1d1d1;
  max-width: 527px;
  margin-top: 12px;
}

.knowMore > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
  margin-top: 18px;
}
.buttonsWrapper > button {
  width: 179px;
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter";
  font-style: normal;
  cursor: pointer;
}
.buttonsWrapper > button:nth-child(1) {
  border: 1.5px solid #212121;
  color: #212121;
  background: #fff;
}
.buttonsWrapper > button:nth-child(2) {
  color: #fff;
  background: #212121;
}
.star {
  margin-left: 8px;
  margin-top: -5px;
}
.eachStar {
  display: flex;
  min-height: 18px;
  align-items: center;
  margin-top: 8px;
}
.eachStar > span:nth-child(1) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.2459px;
  line-height: 22px;
  color: #000000;
}

.eachStar > span:nth-child(2) {
  margin-left: 5px;
  color: #615b5b;
}
.eachStar > span:nth-child(3) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #5a5a5a;
  margin-left: 5px;
}
.startDetails > div:nth-last-child(1),
.startDetails > div:nth-last-child(2) {
  margin-left: 32px;
}
.itemList {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* or 186% */
  position: relative;
  height: fit-content;
  color: #000000;
}
.eachItem {
  border-bottom: 1px solid #d5d5d5;
  padding: 20px;
}

.itemTitle {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  color: #000000;
}

.selected {
  padding-bottom: 12px;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 18px;
}

.arrow {
  margin-right: 8px;
}

.itemRating {
  /* margin-right: 8px; */
  margin-left: auto;
}

.star {
  cursor: pointer;
  font-size: 18px;
  color: #ccc;
  transition: color 0.3s ease;
}

.star.filled,
.star:hover {
  color: #131312;
}

.itemDescription {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  animation: fade-in 0.3s ease;
}

.totalScore {
  /* position: absolute; */
  /* bottom: 0; */
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding: 11px 20px;
  background: #d9d9d9;
  height: fit-content;
  align-items: center;
}
.totalScore > div:nth-child(2) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
.totalValue > div:nth-child(1) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.totalValue > div:nth-child(2) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: #000000;
}

.mainPopUp {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 10;
}
.first {
  min-width: 613px;
  min-height: 292px;
  height: fit-content;
  padding-bottom: 50px;
  margin: 0 auto;
  margin-top: 150px;
  background: #fff;
}
.second > img {
  width: 51px;
  height: 49px;
  cursor: pointer;
}
.second {
  text-align: right;
}
.eligibility {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  padding: 0 35px;
  margin-top: 24px;
  gap: 24px;
}
.eligibility > div > label > input {
  margin-right: 5px;
}
.third {
  max-width: 350px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  /* text-align: center; */
  color: #000000;
  /* margin: 0 auto; */
  padding: 0 35px;
}

.dropDown {
  width: fit-content;
  /* margin: 0 auto; */
  min-width: 200px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 35px;
}
.projectTitle {
  color: #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 30px;
  margin-bottom: 16px;
}
.dropDown > div > div > div > div > input:focus {
  /* border: 1px solid red !important; */
  outline: 1px solid #fff !important;
}

.fourth {
  /* margin: 0 auto; */
  max-width: 400px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* text-align: center; */
  color: #a6a6a6;
  margin-top: 15px;
  padding: 0 35px;
}

.fifth,
.fifthButton {
  display: flex;
  gap: 15px;
  width: fit-content;
  /* margin: 0 auto; */
  margin-top: 26px;
  padding: 0 35px;
}

.fifth > button,
.fifthButton > button {
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
}
/* .fifth > button:nth-child(1) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #212121;
  border: 1.5px solid #212121;
  width: 179px;
  height: 48px;
} */
.fifth > button:nth-child(1),
.fifthButton > button:nth-child(2) {
  width: 179px;
  height: 48px;
  background: #212121;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.datepicker {
  padding: 0 35px;
  color: #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 35px;
}
.datepicker > div {
  display: flex;
  align-items: center;
}
.datepicker > div > div > div > input[type="text"]:focus {
  outline: none !important;
}
.datepicker > div:nth-child(2) {
  border: 1px solid var(--black-20, rgba(17, 17, 19, 0.2));
  width: fit-content;
  padding-right: 16px;
  border-radius: 4px;
}
.datepicker > div > div > img {
  width: 24px;
  height: 24px;
  margin-top: 12px;
  margin-left: -10px;
  background: transparent;
}
.datepicker > div > div > div > input[type="text"] {
  border: none;
  padding: 8px 12px;
  width: 100px;
  color: #111113;
  margin-top: 14px;
  background: transparent;
  /* Body */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  outline: none !important;
}

.projectName{
  cursor: pointer;
  font-size: 1rem;
  margin-top: 16px;
}
.projectName:hover{
  color: blue;
  text-decoration: underline;
}